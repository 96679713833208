//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    footer: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    footerNav() {
      const footerNav = this.$store.getters['navigations/footerNav']
      if (!footerNav) return []
      return footerNav
    },

    hasTags() {
      return !!this.footer.tags.length > 0
    },
  },
}
