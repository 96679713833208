//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getAllServices } from '~/graphql/queries.js'

export default {
  props: {
    page: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      services: {},
      taxonomies: {},
      terms: {},
      activeFilters: [],
      filterOpen: false,
    }
  },

  async fetch() {
    // FETCH DATA
    const lang = this.$i18n.locale === 'de' ? 'de_CH' : this.$i18n.locale
    const data = await this.$graphql.cms.request(getAllServices, { lang })
    this.taxonomies = data.taxonomies
    this.services = data.entries.data
    this.terms = data.terms.data

    // ADD AN Single ARRAY OF ALL TAXONOMIES
    this.services.forEach((service) => {
      service.taxonomies = [
        ...service.serviceart,
        ...service.bereich,
        ...service.schadensart,
      ].map((s) => s.id)
    })
  },

  computed: {
    sortedTaxonomies() {
      if (!this.taxonomies || !this.taxonomies.length) return []
      // sort taxonomies with these three handles always into first place
      const handles = ['schadensart', 'bereich', 'serviceart']
      const sortedTaxonomies = []
      handles.forEach((handle) => {
        const found = this.taxonomies.find((taxonomy) => {
          return taxonomy.handle === handle
        })
        if (found) sortedTaxonomies.push(found)
      })
      // add all elements which left in the old array to the new array
      return sortedTaxonomies.concat(
        this.taxonomies.filter((taxonomy) => {
          return (
            taxonomy.handle !== handles[0] &&
            taxonomy.handle !== handles[1] &&
            taxonomy.handle !== handles[2]
          )
        })
      )
    },

    shownServices() {
      // IF NO FILTER IS ACTIVE SHOW ALL
      if (this.activeFilters.length === 0) {
        return this.services
      }
      // Filter all services by active filters and return them but only if they have all active filters
      return this.services.filter((service) => {
        for (let index = 0; index < this.activeFilters.length; index++) {
          const term = this.activeFilters[index]
          if (!service.taxonomies.includes(term)) {
            return false
          }
        }
        return true
      })
    },
  },

  mounted() {
    // IF DESKOP OPEN FILTER BY DEFAULT
    // if (window.innerWidth > 768) {
    //   this.filterOpen = true
    // }
  },

  methods: {
    ToggleFilter(term) {
      // TOGGEL FILTER
      this.activeFilters.includes(term.id)
        ? (this.activeFilters = this.activeFilters.filter(function (el) {
            return el !== term.id
          }))
        : this.activeFilters.push(term.id)
    },

    filterReset() {
      this.activeFilters = []
    },

    generateTaxonomyTitle(taxonomy) {
      // IF GERMAN RETURN INPUT FORM CMS

      if (this.$i18n.locale === 'de') return taxonomy.title

      // Sorry for this stupid fix but you can't localize taxonomies groupe at the moment
      return this.$t(`services.${taxonomy.title}`)
        ? this.$t(`services.${taxonomy.title}`)
        : ''
    },
  },
}
