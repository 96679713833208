import { render, staticRenderFns } from "./ConsultingBtn.vue?vue&type=template&id=34ee6c6d&"
import script from "./ConsultingBtn.vue?vue&type=script&lang=js&"
export * from "./ConsultingBtn.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppLink: require('/vercel/path0/components/general/AppLink.vue').default})
