//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    nav: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    year() {
      return new Date().getFullYear()
    },
  },
}
