//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    consultingbtn: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      inset: false,
    }
  },

  mounted() {
    setTimeout(() => {
      this.inset = this.consultingbtn.showBtn
    }, 1200)
  },
}
