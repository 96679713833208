/* eslint-disable prettier/prettier */
import { getPageByUri } from "~/graphql/queries.js";

const removeTrailingSlash = (str) =>
  str.endsWith("/") ? str.slice(0, -1) : str;

export default {
  async asyncData({ $graphql, route, app, error, store }) {
    const localeRegex = new RegExp(
      `/(${app.i18n.locales.map((l) => l.code).join("|")})(?=/|$)`
    );
    let uri = route.path.replace(localeRegex, "");
    uri = uri === "" ? "/" : uri;
    uri = uri.replace("//", "");

    // IF LANG DE -> CONV INTO DE_CH FOR BACKEND
    const lang = app.i18n.locale === "de" ? "de_CH" : app.i18n.locale;
    // console.log(getPageByUri)

    try {
      const res = await $graphql.cms.request(getPageByUri, {
        uri,
        lang,
      });

      if (res.entry === null) {
        return error({ statusCode: 404 });
      }
      // consulting btn
      res.ConsultingBtn = {};
      res.ConsultingBtn.text = res.entry?.consulting_btn_text;
      res.ConsultingBtn.showBtn = res.entry?.show_consulting_btn;
      res.ConsultingBtn.link = res.entry?.consulting_btn_link;

      // 🤮

      const languageSlugs = res.entry.alternates.map((alternate) => {
        return [
          alternate.language.replace("de_CH", "de"),
          {
            pathMatch: alternate.url
              .replace("de_ch", "")
              .replace(/^\/([a-z]{2})(_[A-Z]{2})?\//, ""),
          },
        ];
      });

      await Promise.all([
        store.dispatch(
          "i18n/setRouteParams",
          Object.fromEntries(languageSlugs)
        ),
      ]);

      return { res };
    } catch (e) {
      console.error(e);
      if (e.response?.status === 200) {
        return error({ statusCode: 500 });
      } else {
        return error({ statusCode: e.response?.status });
      }
    }
  },

  head() {
    if (!this.res) return {};
    const pageUrl = this.$config.appUrl + this.res.entry.url;
    const pageAlternates = Array.isArray(this.res.entry.alternates)
      ? this.res.entry.alternates ?? []
      : [];

    const alternates = pageAlternates.map((page) => ({
      rel: "alternate",
      hreflang: page.language,
      href: removeTrailingSlash(
        removeTrailingSlash(this.$config.appUrl) + page.url
      ),
    }));

    const defaultAlternate = pageAlternates.find((page) => page.is_root);
    if (defaultAlternate) {
      alternates.push({
        rel: "alternate",
        hreflang: "x-default",
        href: removeTrailingSlash(
          removeTrailingSlash(this.$config.appUrl) + defaultAlternate.url
        ),
      });
    }

    const head = {
      meta: [
        {
          hid: "og:url",
          property: "og:url",
          content: pageUrl,
        },
        {
          hid: "og:type",
          property: "og:type",
          content: "website",
        },
        {
          hid: "og:image",
          property: "og:image",
          content: this.pageImage.permalink,
        },
        {
          hid: "og:image:alt",
          property: "og:image:alt",
          content: this.pageImage.alt,
        },
      ],

      link: [
        {
          hid: "canonical",
          rel: "canonical",
          href: pageUrl,
        },
        // ...alternates,
      ],
    };

    head.meta.push({
      name: "twitter:card",
      content: "summary",
    });

    if (this.res.entry.seo_hidden) {
      head.meta.push({ hid: "robots", name: "robots", content: "noindex" });
    }

    // TITLE
    const pageTitle = this.res.entry.seo_title || this.res.entry.title;

    if (pageTitle) {
      head.title = pageTitle;
      head.meta.push({
        hid: "title",
        property: "og:title",
        content: pageTitle,
      });
    }

    // DESCRIPTION
    if (this.res.entry.seo_description || this.res.entry.description) {
      head.meta.push({
        hid: "description",
        name: "description",
        content: this.res.entry.seo_description || this.res.entry.description,
      });
      head.meta.push({
        property: "og:description",
        content: this.res.entry.seo_description || this.res.entry.description,
      });
    }

    // lang
    head.htmlAttrs = {
      lang: this.$i18n.locale === "dk" ? "da" : this.$i18n.locale,
    };

    return head;
  },

  computed: {
    pageImage() {
      const empty = {
        permalink: undefined,
        alt: undefined,
      };

      // seo image
      if (this.res.entry.seo_image) {
        return this.res.entry.seo_image;
      }

      if (
        !Array.isArray(this.res.entry.heros) ||
        this.res.entry.heros.length === 0
      ) {
        return empty;
      }

      if (
        !Array.isArray(this.res.entry.heros.image) ||
        this.res.entry.heros.images.length === 0
      ) {
        return empty;
      } else {
        return this.res.entry.heros[0].image;
      }
    },
  },
};
