import { render, staticRenderFns } from "./FooterNav.vue?vue&type=template&id=3d77cf08&"
import script from "./FooterNav.vue?vue&type=script&lang=js&"
export * from "./FooterNav.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppLink: require('/vercel/path0/components/general/AppLink.vue').default,Newsletter: require('/vercel/path0/components/nav/Newsletter.vue').default,Footer: require('/vercel/path0/components/nav/Footer.vue').default})
