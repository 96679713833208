//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },

    index: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    img() {
      if (this.data.header[0]) {
        return this.data.header[0].big_image
          ? this.data.header[0].big_image
          : this.data.header[0].smal_images[0]
      }
      return false
    },

    isNew() {
      const now = new Date()
      if (!this.data.new_til_date) {
        return false
      }

      const date = new Date(this.data.new_til_date.replace(/-/g, '/')) // Safari = Cringe
      if (date > now) {
        // selected date is in the future
        return true
      }
      return false
    },
  },
}
