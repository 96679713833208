//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      status: null,
      form: {
        email: '',
      },
    }
  },

  computed: {
    newsletterMessages() {
      return this.$store.getters.newsletterMessages.globalSet
    },
  },

  methods: {
    async submit() {
      // check if there is already a send state
      if (this.status === 'loading' || this.status === 'error') return

      // check if the form is valid
      const isValid = await this.$refs.observer.validate()
      if (!isValid) return
      this.status = this.newsletterMessages?.loading || 'loading'

      // make the request format dk to da because of the newsletter api
      this.form.locale = this.$i18n.locale === 'dk' ? 'da' : this.$i18n.locale

      try {
        // Request

        await this.$axios.$post('/newsletter', this.form, {
          headers: { 'X-Requested-With': 'XMLHttpRequest' },
        })
      } catch (e) {
        this.status = this.newsletterMessages?.error || 'error'
        return
      }
      this.status = this.newsletterMessages?.success || 'success'
      this.$nextTick(() => {
        // Clear errors at submit
        this.$refs.observer.reset()
      })
    },
  },
}
