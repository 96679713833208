//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    page: {
      type: Object,
      required: true,
    },
  },

  // watch: {
  //   page: {
  //     handler() {
  //       this.$store.commit('setBlueprint', this.page.blueprint)
  //       const heroType =
  //         this.page.hero && this.page.hero.length > 0
  //           ? this.page.hero[0].type
  //           : ''
  //       this.$store.commit('setHeroType', heroType)
  //     },

  //     immediate: true,
  //   },
  // },
}
