import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=8bd4588a&"
import script from "./Footer.vue?vue&type=script&lang=js&"
export * from "./Footer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BigTag: require('/vercel/path0/components/general/BigTag.vue').default,SectionFull: require('/vercel/path0/components/general/SectionFull.vue').default,FooterNav: require('/vercel/path0/components/nav/FooterNav.vue').default,Container: require('/vercel/path0/components/general/Container.vue').default})
