import { render, staticRenderFns } from "./UnsereServices.vue?vue&type=template&id=19b824ee&"
import script from "./UnsereServices.vue?vue&type=script&lang=js&"
export * from "./UnsereServices.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ComponentLoader: require('/vercel/path0/components/loaders/ComponentLoader.vue').default,FilterArrowIcon: require('/vercel/path0/components/icons/FilterArrowIcon.vue').default,ServiceCard: require('/vercel/path0/components/general/ServiceCard.vue').default})
